import { FC } from 'react';

import Footer from 'components/layout/Footer';
import SiteMetaData, { MetaProps } from 'components/layout/SiteMetaData';

const Layout: FC<MetaProps> = ({ children, noindex }) => (
  <div className='layout'>
    <SiteMetaData noindex={noindex} />
    <title>Alpaca Guild</title>
    {children}
    <Footer />
  </div>
);

export default Layout;

import { FC } from 'react';

import { navigate } from 'gatsby';

import RightArrowIcon from 'images/icons/arrow-icon.svg';

const BackButton: FC = () => (
  <div className='back-button'>
    <button
      type='button'
      onClick={() => {
        if (typeof window !== 'undefined') {
          void (window.history.state ? navigate(-1) : navigate('/'));
        }
      }}
      className='btn btn-back'
    >
      <RightArrowIcon />
      Back
    </button>
  </div>
);

export default BackButton;
